/*eslint-disable */
import {bus} from '@/main';  
import toast from '@/services/toast';
import { Datetime } from 'vue-datetime'
// You need a specific loader for CSS files
import 'vue-datetime/dist/vue-datetime.css'
export default {
  name: 'update-appointment-details-modal',
  components: {
    datetime: Datetime
  },
  props: ['updateAppointmentID'],
  data () {
    return {
      symptoms:'',
      record:'',
      submitted:false,
      appointmentDateTime:'',
      symptomsArr:[
        {name:'Fever', code:'Fever'},
        {name:'Acne', code:'Acne'},
        {name:'Blocked Nose', code:'Blocked Nose'},
        {name:'Runny Nose', code:'Runny Nose'},
        {name:'Itching', code:'Itching'},
        {name:'Headache', code:'Headache'},
        {name:'Vomiting', code:'Vomiting'},
        {name:'Constipation', code:'Constipation'},
        {name:'Throat pain', code:'Throat pain'},
        {name:'Rashes', code:'Rashes'},
        {name:'Sneezing', code:'Sneezing'},
        {name:'Hairfall', code:'Hairfall'},
        {name:'Abdominal Pain', code:'Abdominal Pain'},
        {name:'Spots On Skin', code:'Spots On Skin'},
        {name:'Obesity', code:'Obesity'},
        {name:'Gas', code:'Gas'},
        {name:'Erectile Dysfunction', code:'Erectile Dysfunction'},
        {name:'Bone Pain', code:'Bone Pain'},
        {name:'Dry Skin', code:'Dry Skin'},
      ],
      uploadedHRList:[],
      minDatetime:new Date(),
      videoFile:'',
      reason:'',
      is_scheduled:false,
      is_scheduled_2:false
    }
  },
  computed: {
  },
  mounted () {
    this.$store.dispatch('getAppointmentDetailByIdApiFun', {id:this.updateAppointmentID}).then((res)=>{
      this.appointmentDateTime = res.data.data.appointment_time
      this.is_scheduled = res.data.data.is_scheduled
      this.is_scheduled_2 = res.data.data.is_scheduled_2
    }).catch((err)=>{})
  },
  methods: {
    getAppointmentListAgainMethod(){
      this.$store.dispatch('getAppointmentListApiFun').then((res)=>{
      })
    },
    updateAppointmentFormBeforeSubmit(){
      this.$validator.validateAll().then((result) => {
        if(result){
          this.submitted = true;
          let data = {
            appointment_time:this.appointmentDateTime,
            reason:this.reason,
            is_scheduled:true
          }
          if(this.is_scheduled){
            data.is_scheduled_2 = true;
          }
          this.$store.dispatch('updateAppointmentDetailsApiFun', {data:{data:data,id:this.updateAppointmentID}}).then((res)=>{
            let notificationPayload = { 
              type:'appointment_rescheduled',
              data_id:this.updateAppointmentID,
              message:"Appointment has been rescheduled"
            }
            this.$store.dispatch('postNotificationFunc', notificationPayload)
            toast.success(res.data.msg);
            this.getAppointmentListAgainMethod()
            this.closeUpdateAppointmentDetailModal()           
            this.submitted =  false
          }).catch((err)=>{
            toast.error(err.data.user_msg || err.data.msg);
            this.submitted =  false
          })
        return;
        }
      })
    },
    closeUpdateAppointmentDetailModal(){
      bus.$emit('updateAppointmentDetailModalBus', false)
      document.body.classList.remove('popup_open_body')
    }
  }
}